import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { HeroContainer } from "@containers";
import { HeaderLayout, FooterLayout, Layout } from "@layouts";
import { getPageItemByScope, getSetting, hasPageItemByScope } from "@definitions/utils";


const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundQuery {
      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "not-found"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
`);

  return (
    <Layout
      title={getSetting(data.page?.pageSettings, "title")}
      className="white-version"
    >
      {(data.header && data.router) && (
        <HeaderLayout
          data={{
            header: data.header,
            router: data.router,
            socials: data.site?.siteMetadata?.socials
          }}
          className="rn-d-none"
        />
      )}
        <main className="main-page-wrapper">
          {hasPageItemByScope(data.page?.items, "hero") && (
            <HeroContainer
              id="hero"
              data={getPageItemByScope(data.page?.items, "hero")}
            />
          )}

        <div style={{"margin-top": "100px", "text-align": "center"}}>
          <span style={{"font-size": "40px", "font-weight": "bold"}}>404 - Not Found</span>
        </div>

        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
          className="section-separator"
        />
      )}
    </Layout>
  );
}


export default NotFoundPage;
